

import {getWithExpiry, removeKey} from './util/customSessionManager';
import isEmpty from './util/isEmpty';


const userDetails = getWithExpiry('userDetails');

export default {
    //defaultPath: '/calculator/comparison',
    defaultPath: '/dashboard/sales',
    basename: '/cms', // only at build time to set, like ///able-pro/react/default
    layout: 'vertical', // vertical, horizontal
    subLayout: '', // horizontal-2
    collapseMenu: false, // mini-menu
    layoutType: 'menu-light', // menu-dark, menu-light, dark
    headerBackColor: 'header-blue', // background-blue, background-red, background-purple, background-info, background-green, background-dark, background-grd-blue, background-grd-red, background-grd-purple, background-grd-info, background-grd-green, background-grd-dark, background-img-1, background-img-2, background-img-3, background-img-4, background-img-5, background-img-6
    rtlLayout: false,
    navFixedLayout: true,
    headerFixedLayout: false,
    boxLayout: false,
    
    showAddressBreakdownBlocks: false,
    comparisonOptions: getWithExpiry('ep_comparisonOptions'),

    distributorByState: [],
    distributorByStatePostcodeSuburb: [],
    gasDistributorByStatePostcodeSuburb: [],
    ntcByDistributorCustomerType: [],
    leadId: null,
    leadList: null,
    leadVANNumber: null,

    fetchedLeadDetails: null,
    fetchedLeadWashReceipt: null,
    fetchedCustomerDetails: null,

    signupStateDetails: getWithExpiry('ep_signupStateDetails'),

    applicationLoader: false,
    recordUpdateStatus: null,
    error: null,

    loggedInUserDetails: !isEmpty(userDetails) ? userDetails : null,

    dashboardSaleStats: null,

    reatilerQAQuestions: null,
    callQARecordResult: null,
};
