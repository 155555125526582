
import * as actionTypes from './actions';
import config from './../config';
import { setWithExpiry, getWithExpiry, removeKey } from "../util/customSessionManager"

const initialState = {
  isOpen: [], //for active default menu
  isTrigger: [], //for active default menu, set blank for horizontal
  ...config,
  isFullScreen: false, // static can't change
};

const reducer = (state = initialState, action) => {

    let trigger = [];
    let open = [];

    switch (action.type) {
        case actionTypes.SAVE_SIGNUP_STATE:
            setWithExpiry('ep_signupStateDetails',action.result)
            return {
                ...state,
                signupStateDetails: action.result
            };
        case actionTypes.SHOW_BREAKDOWN_ADDRESS_BLOCKS:
            return {
                ...state,
                showAddressBreakdownBlocks: !state.showAddressBreakdownBlocks
            };
        case actionTypes.SAVE_RETAILER_QA_QUESTIONS:
            return {
                ...state,
                reatilerQAQuestions: action.resultList
            };
        case actionTypes.SAVE_CALL_QA_RECORD:
            return {
                ...state,
                callQARecordResult: action.callQARecordResult
            };
        case actionTypes.SAVE_COMPARISON_OPTIONS:
            setWithExpiry('ep_comparisonOptions',action.resultList)
            return {
                ...state,
                comparisonOptions: action.resultList
            };
        case actionTypes.SAVE_ALL_ELEC_DISTRIBUTORS:
            return {
                ...state,
                allElecDistributors: action.resultList
            };
        case actionTypes.SAVE_ALL_GAS_DISTRIBUTORS:
            return {
                ...state,
                allGasDistributors: action.resultList
            };
        case actionTypes.SAVE_ALL_RETAILERS:
            return {
                ...state,
                allRetailers: action.resultList
            };
        case actionTypes.SAVE_DISTRIBUTOR_BY_STATE:
            return {
                ...state,
                distributorByState: action.resultList
            };
        case actionTypes.SAVE_DISTRIBUTOR_BY_STATE_POSTCODE_SUBURB:
            return {
                ...state,
                distributorByStatePostcodeSuburb: action.resultList
            };
        case actionTypes.SAVE_GAS_DISTRIBUTOR_BY_STATE_POSTCODE_SUBURB:
            return {
                ...state,
                gasDistributorByStatePostcodeSuburb: action.resultList
            };
        case actionTypes.SAVE_NTC_BY_DISTRIBUTOR_CUSTOMER_TYPE:
            return {
                ...state,
                ntcByDistributorCustomerType: action.resultList
            };

        case actionTypes.SAVE_LEAD_CUSTOMER_DETAILS:
            removeKey("ep_signupStateDetails")
            removeKey("ep_comparisonOptions")
            return {
                ...state,
                leadId: action.recordDetails.leadId,
                fetchedLeadDetails: action.recordDetails.leadDetails,
                fetchedLeadWashReceipt: action.recordDetails.leadWashReceipt,
                fetchedCustomerDetails: action.recordDetails.customerDetails,
                signupStateDetails: null,
                comparisonOptions: [],
            };

        case actionTypes.SAVE_LEAD_VAN_NUMBER:
            return {
                ...state,
                leadVANNumber: action.result
            };

        case actionTypes.SAVE_LEAD_LIST:
            return {
                ...state,
                leadList: action.leadList
            };

        case actionTypes.SAVE_RECORD_UPDATE_STATUS:
            return {
                ...state,
                recordUpdateStatus: action.recordUpdateStatus
            };
        case actionTypes.ERROR:
            return {
                ...state,
                error: action.error
            };

        case actionTypes.SET_APPLICATION_LOADER_STATUS:
            return {
                ...state,
                applicationLoader: action.status
            };

        case actionTypes.CLEAR_ALL_COMPARISON_DATA:
            removeKey("ep_signupStateDetails")
            removeKey("ep_comparisonOptions")
            return {
                ...state,
                comparisonOptions: [],

                distributorByState: [],
                distributorByStatePostcodeSuburb: [],
                gasDistributorByStatePostcodeSuburb: [],
                ntcByDistributorCustomerType: [],

                leadId: null,
                leadList: null,
                leadVANNumber: null,

                fetchedLeadDetails: null,
                fetchedLeadWashReceipt: null,
                fetchedCustomerDetails: null,

                signupStateDetails: null,

                recordUpdateStatus: null,
                error: null,
            };

        case actionTypes.CLEAR_ALL_AFTER_QA:
            return {
                ...state,
                callQARecordResult: null,
                reatilerQAQuestions: null,
                recordUpdateStatus: null,
                error: null,
            };

        
        case actionTypes.CLEAR_ALL_LEAD_DATA:
            removeKey("ep_signupStateDetails")
            removeKey("ep_comparisonOptions")
            return {
                ...state,
                leadId: null,
                leadVANNumber: null,

                fetchedLeadDetails: null,
                fetchedCustomerDetails: null,
                fetchedLeadWashReceipt: null,

                signupStateDetails: null,

                comparisonOptions: [],

                distributorByState: [],
                distributorByStatePostcodeSuburb: [],
                gasDistributorByStatePostcodeSuburb: [],
                ntcByDistributorCustomerType: [],

                recordUpdateStatus: null,
                error: null,
            };

        case actionTypes.SAVE_USER_DETAILS:
            return {
                ...state,
                loggedInUserDetails: action.userDetails
            };
        case actionTypes.SAVE_ACCESS_CONTROLS:
            return {
                ...state,
                accessControls: action.accessControls
            };
        case actionTypes.SAVE_ACCESS_CONTROL:
            return {
                ...state,
                accessControl: action.recordDetails
            };
        case actionTypes.CLEAR_ALL_ACCESS_CONTROL_DATA:
            return {
                ...state,
                accessControl: null
            };

        case actionTypes.SAVE_DASHBOARD_SALES_STATS:
            return {
                ...state,
                dashboardSaleStats: action.saleStats
            };


        case actionTypes.COLLAPSE_MENU:
            return {
                ...state,
                collapseMenu: !state.collapseMenu
            };
        case actionTypes.COLLAPSE_TOGGLE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }

                if (triggerIndex === -1) {
                    open = [...open, action.menu.id];
                    trigger = [...trigger, action.menu.id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = (state.isTrigger).indexOf(action.menu.id);
                trigger = (triggerIndex === -1) ? [action.menu.id] : [];
                open = (triggerIndex === -1) ? [action.menu.id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger
            };
        case actionTypes.NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case actionTypes.NAV_COLLAPSE_LEAVE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }
                return {
                    ...state,
                    isOpen: open,
                    isTrigger: trigger,
                };
            }
            return {...state};
        case actionTypes.FULL_SCREEN :
            return {
                ...state,
                isFullScreen: !state.isFullScreen
            };
        case actionTypes.FULL_SCREEN_EXIT:
            return {
                ...state,
                isFullScreen: false
            };
        case actionTypes.CHANGE_LAYOUT:
            return {
                ...state,
                layout: action.layout
            };
        case actionTypes.CHANGE_SUB_LAYOUT:
            return {
                ...state,
                subLayout: action.subLayout
            };
        case actionTypes.LAYOUT_TYPE:
            return {
                ...state,
                layoutType: action.layoutType,
                headerBackColor: initialState.headerBackColor
            };
        case actionTypes.NAV_BACK_COLOR:
            return {
                ...state,
                layoutType: (state.layoutType === 'menu-light') ? 'menu-dark' : state.layoutType
            };
        case actionTypes.HEADER_BACK_COLOR:
            return {
                ...state,
                headerBackColor: action.headerBackColor
            };
        case actionTypes.RTL_LAYOUT:
            return {
                ...state,
                rtlLayout: !state.rtlLayout
            };
        case actionTypes.NAV_FIXED_LAYOUT:
            return {
                ...state,
                navFixedLayout: !state.navFixedLayout
            };
        case actionTypes.HEADER_FIXED_LAYOUT:
            return {
                ...state,
                headerFixedLayout: !state.headerFixedLayout,
                headerBackColor: (!state.headerFixedLayout && initialState.headerBackColor === 'header-default') ? 'header-blue' : state.headerBackColor,
            };
        case actionTypes.BOX_LAYOUT:
            return {
                ...state,
                boxLayout: !state.boxLayout
            };
        case actionTypes.RESET:
            return {
                ...state,
                layout: initialState.layout,
                subLayout: initialState.subLayout,
                collapseMenu: initialState.collapseMenu,
                layoutType: initialState.layoutType,
                headerBackColor: initialState.headerBackColor,
                rtlLayout: initialState.rtlLayout,
                navFixedLayout: initialState.navFixedLayout,
                headerFixedLayout: initialState.headerFixedLayout,
                boxLayout: initialState.boxLayout
            };
        case actionTypes.SAVE_CUSTOMER_LIST:
            return {
                ...state,
                customerList: action.customerList
            };
        case actionTypes.SAVE_USERS:
            return {
                ...state,
                userList: action.userList
            };    
        case actionTypes.SAVE_CUSTOMER_DETAILS:
            return {
                ...state,
                customerId: action.recordDetails._id,
                fetchedCustomerDetails: action.recordDetails
            };
        case actionTypes.CLEAR_ALL_CUSTOMER_DATA:
            return {
                ...state,
                customerId: null,
                fetchedCustomerDetails: null
            };
        case actionTypes.SAVE_NETWORK_TARIFF_MAPPINGS:
            return {
                ...state,
                networkTariffMappings: action.networkTariffMappings
            };
        case actionTypes.SAVE_NETWORK_TARIFF_MAPPING:
            return {
                ...state,
                networkTariffMapping: action.networkTariffMapping
            };
        case actionTypes.CLEAR_ALL_NETWORK_TARIFF_DATA:
            return {
                ...state,
                networkTariffMapping: null
            }
        case actionTypes.SAVE_ELECTRICITY_POST_CODE_MAPPINGS:
            return {
                ...state,
                elecPostCodeMappings: action.elecPostCodeMappings
            };
        case actionTypes.SAVE_ELECTRICITY_POST_CODE_MAPPING:
            return {
                ...state,
                elecPostCodeMapping: action.elecPostCodeMapping
            };
        case actionTypes.CLEAR_ALL_ELECTRICITY_POST_CODE_MAPPING_DATA:
            return {
                ...state,
                elecPostCodeMapping: null
            };
        case actionTypes.SAVE_POST_CODES:
            return {
                ...state,
                postCodes: action.postCodes
            };
        case actionTypes.SAVE_SUBURBS:
            return {
                ...state,
                suburbs: action.suburbs
            };
        case actionTypes.SAVE_GAS_POST_CODE_MAPPINGS:
            return {
                ...state,
                gasPostCodeMappings: action.gasPostCodeMappings
            };
        case actionTypes.SAVE_GAS_POST_CODE_MAPPING:
            return {
                ...state,
                gasPostCodeMapping: action.gasPostCodeMapping
            };
        case actionTypes.CLEAR_ALL_GAS_POST_CODE_MAPPING_DATA:
            return {
                ...state,
                gasPostCodeMapping: null
            };
        case actionTypes.SAVE_INTERNAL_TARIFFS:
            return {
                ...state,
                internalTariffs: action.internalTariffs
            };
        case actionTypes.SAVE_INTERNAL_TARIFF:
            return {
                ...state,
                internalTariff: action.internalTariff
            };
        case actionTypes.CLEAR_ALL_INTERNAL_TARIFF_DATA:
            return {
                ...state,
                internalTariff: null
            }
        case actionTypes.SAVE_INTERNAL_TARIFF_CODES:
            return {
                ...state,
                internalTariffCodes: action.internalTariffCodes
            };
        case actionTypes.SAVE_PRODUCT_LIST:
            return {
                ...state,
                products: action.products
            };
        case actionTypes.SAVE_PRODUCT_DETAILS:
            return {
                ...state,
                product: action.product
            };
        case actionTypes.CLEAR_ALL_PRODUCT_DATA:
            return {
                ...state,
                product: null
            }
        case actionTypes.UPDATE_RETAILER_SALES_SUBMISSION_STATUS:
                return {
                    ...state,
                    resultListSalesSubmissionStatus: action.resultListSalesSubmissionStatus
            }
        case actionTypes.UPDATE_RETAILER_SALES_STATUS:
            return {
                ...state,
                resultListSalesStatus: action.resultListSalesStatus
            }
        case actionTypes.UPDATE_RETAILER_SALES_PAYMENT_STATUS:
                return {
                    ...state,
                    resultListSalesPaymentStatus: action.resultListSalesPaymentStatus
            }
        case actionTypes.SAVE_SAVINGS_MAPPINGS:
      return {
        ...state,
        vendorsMappings: action.vendorsMappings
      }
    case actionTypes.SAVE_VENDOR_DETAILS:
      return {
        ...state,
        recordDetails: action.recordDetails
      }
    case actionTypes.SAVE_VENDOR_RECORD_SAVE_STATUS:
      return {
        ...state,
        recordSaveStatus: action.recordSaveStatus
      }
    case actionTypes.SAVE_VENDOR_RECORD_UPDATE_STATUS:
      return {
        ...state,
        recordUpdateStatus: action.recordUpdateStatus
      }
    case actionTypes.SAVE_ADDRESS_SAVINGS_MAPPINGS:
      return {
        ...state,
        addressListMappings: action.addressListMappings
      }
    case actionTypes.SAVE_ADDRESS_DETAILS:
      return {
        ...state,
        recordAddressDetails: action.recordAddressDetails
      }
    case actionTypes.SAVE_ADDRESS_RECORD_SAVE_STATUS:
      return {
        ...state,
        recordAddressSaveStatus: action.recordAddressSaveStatus
      }
    case actionTypes.SAVE_ELEC_RATES_SAVINGS_MAPPINGS:
      return {
        ...state,
        elecRatesListMappings: action.elecRatesListMappings
      }
    case actionTypes.SAVE_ELEC_RATES_DETAILS:
      return {
        ...state,
        recordElecRatesDetails: action.recordElecRatesDetails
      }
    case actionTypes.SAVE_ELEC_RATES_RECORD_SAVE_STATUS:
      return {
        ...state,
        recordSaveStatus: action.recordSaveStatus
      }
    case actionTypes.SAVE_ELEC_RATES_RECORD_UPDATE_STATUS:
      return {
        ...state,
        recordUpdateStatus: action.recordUpdateStatus
      }
    case actionTypes.SAVE_GAS_RATES_SAVINGS_MAPPINGS:
      return {
        ...state,
        gasRatesListMappings: action.gasRatesListMappings
      }
    case actionTypes.SAVE_GAS_RATES_DETAILS:
      return {
        ...state,
        recordGasRatesDetails: action.recordGasRatesDetails
      }
    case actionTypes.SAVE_GAS_RATES_RECORD_SAVE_STATUS:
      return {
        ...state,
        recordSaveStatus: action.recordSaveStatus
      }
    case actionTypes.SAVE_GAS_RATES_RECORD_UPDATE_STATUS:
      return {
        ...state,
        recordUpdateStatus: action.recordUpdateStatus
      }

    case actionTypes.SAVE_ELEC_DIS_FEE_SAVINGS_MAPPINGS:
      return {
        ...state,
        elecDisFeesListMappings: action.elecDisFeesListMappings
      }
    case actionTypes.SAVE_ELEC_DIS_FEE_DETAILS:
      return {
        ...state,
        recordElecDisFeesDetails: action.recordElecDisFeesDetails
      }
    case actionTypes.SAVE_ELEC_DIS_FEE_RECORD_SAVE_STATUS:
      return {
        ...state,
        recordSaveStatus: action.recordSaveStatus
      }
    case actionTypes.SAVE_ELEC_DIS_FEE_RECORD_UPDATE_STATUS:
      return {
        ...state,
        recordUpdateStatus: action.recordUpdateStatus
      }
    case actionTypes.SAVE_ADDRESS_RECORD_UPDATE_STATUS:
      return {
        ...state,
        recordAddressUpdateStatus: action.recordAddressUpdateStatus
      }

    case actionTypes.SAVE_ELEC_BPID_SAVINGS_MAPPINGS:
      return {
        ...state,
        elecBPIDsListMappings: action.elecBPIDsListMappings
      }
    case actionTypes.SAVE_ELEC_BPID_DETAILS:
      return {
        ...state,
        recordElecBPIDsDetails: action.recordElecBPIDsDetails
      }
    case actionTypes.SAVE_ELEC_BPID_RECORD_SAVE_STATUS:
      return {
        ...state,
        recordSaveStatus: action.recordSaveStatus
      }
    case actionTypes.SAVE_ELEC_BPID_RECORD_UPDATE_STATUS:
      return {
        ...state,
        recordUpdateStatus: action.recordUpdateStatus
      }
    case actionTypes.SAVE_GAS_BPID_SAVINGS_MAPPINGS:
      return {
        ...state,
        gasBPIDsListMappings: action.gasBPIDsListMappings
      }
    case actionTypes.SAVE_GAS_BPID_DETAILS:
      return {
        ...state,
        recordGasBPIDsDetails: action.recordGasBPIDsDetails
      }
    case actionTypes.SAVE_GAS_BPID_RECORD_SAVE_STATUS:
      return {
        ...state,
        recordSaveStatus: action.recordSaveStatus
      }
    case actionTypes.SAVE_GAS_BPID_RECORD_UPDATE_STATUS:
      return {
        ...state,
        recordUpdateStatus: action.recordUpdateStatus
      }
    case actionTypes.SAVE_RETAILER_REWARDS_SAVINGS_MAPPINGS:
      return {
        ...state,
        retailerRewardsListMappings: action.retailerRewardsListMappings
      }
    case actionTypes.SAVE_RETAILER_REWARDS_DETAILS:
      return {
        ...state,
        recordRetailerRewardsDetails: action.recordRetailerRewardsDetails
      }
    case actionTypes.SAVE_RETAILER_REWARDS_RECORD_SAVE_STATUS:
      return {
        ...state,
        recordSaveStatus: action.recordSaveStatus
      }
    case actionTypes.SAVE_RETAILER_REWARDS_RECORD_UPDATE_STATUS:
      return {
        ...state,
        recordUpdateStatus: action.recordUpdateStatus
      }
    case actionTypes.SAVE_DMOVDO_SCRIPT_SAVINGS_MAPPINGS:
      return {
        ...state,
        dmovdoScriptsListMappings: action.dmovdoScriptsListMappings
      }
    case actionTypes.SAVE_DMOVDO_SCRIPT_DETAILS:
      return {
        ...state,
        recordDmovdoScriptsDetails: action.recordDmovdoScriptsDetails
      }
    case actionTypes.SAVE_DMOVDO_SCRIPT_RECORD_SAVE_STATUS:
      return {
        ...state,
        recordSaveStatus: action.recordSaveStatus
      }
    case actionTypes.SAVE_DMOVDO_SCRIPT_RECORD_UPDATE_STATUS:
      return {
        ...state,
        recordUpdateStatus: action.recordUpdateStatus
      }
    case actionTypes.SAVE_GAS_DISTRIBUTORS_SAVINGS_MAPPINGS:
      return {
        ...state,
        gasDistributorListMappings: action.gasDistributorListMappings
      }
    case actionTypes.SAVE_GAS_DISTRIBUTORS_DETAILS:
      return {
        ...state,
        recordGasDistributorDetails: action.recordGasDistributorDetails
      }
    case actionTypes.SAVE_GAS_DISTRIBUTORS_RECORD_SAVE_STATUS:
      return {
        ...state,
        recordSaveStatus: action.recordSaveStatus
      }
    case actionTypes.SAVE_GAS_DISTRIBUTORS_RECORD_UPDATE_STATUS:
      return {
        ...state,
        recordUpdateStatus: action.recordUpdateStatus
      }
    case actionTypes.SAVE_ELEC_DISTRIBUTORS_SAVINGS_MAPPINGS:
      return {
        ...state,
        elecDistributorListMappings: action.elecDistributorListMappings
      }
    case actionTypes.SAVE_ELEC_DISTRIBUTORS_DETAILS:
      return {
        ...state,
        recordElecDistributorDetails: action.recordElecDistributorDetails
      }
    case actionTypes.SAVE_ELEC_DISTRIBUTORS_RECORD_SAVE_STATUS:
      return {
        ...state,
        recordSaveStatus: action.recordSaveStatus
      }
    case actionTypes.SAVE_ELEC_DISTRIBUTORS_RECORD_UPDATE_STATUS:
      return {
        ...state,
        recordUpdateStatus: action.recordUpdateStatus
      }
    case actionTypes.SAVE_ONLINE_SIGNUP_SCRIPTS_SAVINGS_MAPPINGS:
      return {
        ...state,
        onlineSignupScriptsListMappings: action.onlineSignupScriptsListMappings
      }
    case actionTypes.SAVE_ONLINE_SIGNUP_SCRIPTS_DETAILS:
      return {
        ...state,
        recordOnlineSignupScriptsDetails: action.recordOnlineSignupScriptsDetails
      }
    case actionTypes.SAVE_ONLINE_SIGNUP_SCRIPTS_RECORD_SAVE_STATUS:
      return {
        ...state,
        recordSaveStatus: action.recordSaveStatus
      }
    case actionTypes.SAVE_ONLINE_SIGNUP_SCRIPTS_RECORD_UPDATE_STATUS:
      return {
        ...state,
        recordUpdateStatus: action.recordUpdateStatus
      }
    case actionTypes.SAVE_GAS_DISTRIBUTORS_FEES_SAVINGS_MAPPINGS:
        return {
          ...state,
          gasDistributorFeesListMappings: action.gasDistributorFeesListMappings
        }
      case actionTypes.SAVE_GAS_DISTRIBUTORS_FEES_DETAILS:
        return {
          ...state,
          recordGasDistributorFeesDetails: action.recordGasDistributorFeesDetails
        }
      case actionTypes.SAVE_GAS_DISTRIBUTORS_FEES_RECORD_SAVE_STATUS:
        return {
          ...state,
          recordSaveStatus: action.recordSaveStatus
        }
      case actionTypes.SAVE_GAS_DISTRIBUTORS_FEES_RECORD_UPDATE_STATUS:
        return {
          ...state,
          recordUpdateStatus: action.recordUpdateStatus
        }
    case actionTypes.SAVE_ALL_COMPARISONS_SAVINGS_MAPPINGS:
      return {
        ...state,
        allComparisonsListMappings: action.allComparisonsListMappings
      }
    case actionTypes.SAVE_RETAILER_SCRIPTS_SAVINGS_MAPPINGS:
      return {
        ...state,
        retailerScriptsListMappings: action.retailerScriptsListMappings
      }
    case actionTypes.SAVE_RETAILER_SCRIPTS_DETAILS:
      return {
        ...state,
        recordRetailerScriptsDetails: action.recordRetailerScriptsDetails
      }
    case actionTypes.SAVE_RETAILER_SCRIPTS_RECORD_SAVE_STATUS:
      return {
        ...state,
        recordSaveStatus: action.recordSaveStatus
      }
    case actionTypes.SAVE_RETAILER_SCRIPTS_RECORD_UPDATE_STATUS:
      return {
        ...state,
        recordUpdateStatus: action.recordUpdateStatus
      }
        case 'SAVE_ADDRESS_LIST':
            return {
                ...state,
                addressList: action.addressList
            };
        case 'SAVE_NMI_DETAILS':
            return {
                ...state,
                nmiDetails: action.nmiDetails
            };
        case 'SAVE_SELECTED_ADDRESS':
            return {
                ...state,
                selectedAddress: action.selectedAddress
            };
          case 'SAVE_QA_REPORT':
            return {
              ...state,
              qaReportData: action.qaReportData
            }
        default:
            return state;
    }
};

export default reducer;
