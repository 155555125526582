export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_SUB_LAYOUT = "CHANGE_SUB_LAYOUT";
export const LAYOUT_TYPE = "LAYOUT_TYPE";
export const RESET = "RESET";
export const NAV_BACK_COLOR = "NAV_BACK_COLOR";
export const NAV_BRAND_COLOR = "NAV_BRAND_COLOR";
export const HEADER_BACK_COLOR = "HEADER_BACK_COLOR";
export const RTL_LAYOUT = "RTL_LAYOUT";
export const NAV_FIXED_LAYOUT = "NAV_FIXED_LAYOUT";
export const HEADER_FIXED_LAYOUT = "HEADER_FIXED_LAYOUT";
export const BOX_LAYOUT = "BOX_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";

export const SHOW_BREAKDOWN_ADDRESS_BLOCKS = "SHOW_BREAKDOWN_ADDRESS_BLOCKS";
export const SAVE_COMPARISON_OPTIONS = "SAVE_COMPARISON_OPTIONS";
export const SAVE_ALL_ELEC_DISTRIBUTORS = "SAVE_ALL_ELEC_DISTRIBUTORS";
export const SAVE_ALL_GAS_DISTRIBUTORS = "SAVE_ALL_GAS_DISTRIBUTORS";
export const SAVE_DISTRIBUTOR_BY_STATE = "SAVE_DISTRIBUTOR_BY_STATE";
export const SAVE_DISTRIBUTOR_BY_STATE_POSTCODE_SUBURB =
  "SAVE_DISTRIBUTOR_BY_STATE_POSTCODE_SUBURB";
export const SAVE_GAS_DISTRIBUTOR_BY_STATE_POSTCODE_SUBURB =
  "SAVE_GAS_DISTRIBUTOR_BY_STATE_POSTCODE_SUBURB";
export const SAVE_NTC_BY_DISTRIBUTOR_CUSTOMER_TYPE =
  "SAVE_NTC_BY_DISTRIBUTOR_CUSTOMER_TYPE";

export const SAVE_ALL_VENDORS = "SAVE_ALL_VENDORS";
//LEAD
export const SAVE_LEAD_CUSTOMER_DETAILS = "SAVE_LEAD_CUSTOMER_DETAILS";
export const SAVE_LEAD_VAN_NUMBER = "SAVE_LEAD_VAN_NUMBER";
export const SAVE_LEAD_LIST = "SAVE_LEAD_LIST";


export const SAVE_SIGNUP_STATE = "SAVE_SIGNUP_STATE";

export const SAVE_RECORD_UPDATE_STATUS = 'SAVE_RECORD_UPDATE_STATUS';
export const DELETE_RECORD_STATUS = "DELETE_RECORD_STATUS";
export const ERROR = 'ERROR';
export const SET_APPLICATION_LOADER_STATUS = 'SET_APPLICATION_LOADER_STATUS';


export const CLEAR_ALL_LEAD_DATA = "CLEAR_ALL_LEAD_DATA";
export const CLEAR_ALL_COMPARISON_DATA = "CLEAR_ALL_COMPARISON_DATA";

export const SAVE_AUTH_TOKEN = 'SAVE_AUTH_TOKEN';
export const SAVE_USER_DETAILS = 'SAVE_USER_DETAILS';

export const SAVE_ACCESS_CONTROLS = 'SAVE_ACCESS_CONTROLS';
export const SAVE_ACCESS_CONTROL = 'SAVE_ACCESS_CONTROL';
export const CLEAR_ALL_ACCESS_CONTROL_DATA = 'CLEAR_ALL_ACCESS_CONTROL_DATA';

export const SAVE_DASHBOARD_SALES_STATS = "SAVE_DASHBOARD_SALES_STATS";

export const SAVE_RETAILER_QA_QUESTIONS = "SAVE_RETAILER_QA_QUESTIONS";
export const SAVE_CALL_QA_RECORD = "SAVE_CALL_QA_RECORD";

export const CLEAR_ALL_AFTER_QA = "CLEAR_ALL_AFTER_QA";

//CUSTOMER
export const SAVE_CUSTOMER_LIST = "SAVE_CUSTOMER_LIST";
export const SAVE_CUSTOMER_DETAILS = "SAVE_CUSTOMER_DETAILS";
export const CLEAR_ALL_CUSTOMER_DATA = "CLEAR_ALL_CUSTOMER_DATA";

//NETWORK TARIFF MAPPINGS
export const SAVE_NETWORK_TARIFF_MAPPINGS = "SAVE_NETWORK_TARIFF_MAPPINGS";
export const SAVE_NETWORK_TARIFF_MAPPING = "SAVE_NETWORK_TARIFF_MAPPING";
export const CLEAR_ALL_NETWORK_TARIFF_DATA = "CLEAR_ALL_NETWORK_TARIFF_DATA";

//ELECTRICITY POST CODE MAPPING
export const SAVE_ELECTRICITY_POST_CODE_MAPPINGS = 'SAVE_ELECTRICITY_POST_CODE_MAPPINGS';
export const SAVE_ELECTRICITY_POST_CODE_MAPPING = 'SAVE_ELECTRICITY_POST_CODE_MAPPING';
export const CLEAR_ALL_ELECTRICITY_POST_CODE_MAPPING_DATA = 'CLEAR_ALL_ELECTRICITY_POST_CODE_MAPPING_DATA';
export const SAVE_POST_CODES = 'SAVE_POST_CODES';
export const SAVE_SUBURBS = 'SAVE_SUBURBS';

//GAS POST CODE MAPPING
export const SAVE_GAS_POST_CODE_MAPPINGS = "SAVE_GAS_POST_CODE_MAPPINGS";
export const SAVE_GAS_POST_CODE_MAPPING = "SAVE_GAS_POST_CODE_MAPPING";
export const CLEAR_ALL_GAS_POST_CODE_MAPPING_DATA =
  "CLEAR_ALL_GAS_POST_CODE_MAPPING_DATA";

//INTERNAL TARIFF
export const SAVE_INTERNAL_TARIFFS = "SAVE_INTERNAL_TARIFFS";
export const SAVE_INTERNAL_TARIFF = "SAVE_INTERNAL_TARIFF";
export const CLEAR_ALL_INTERNAL_TARIFF_DATA = "CLEAR_ALL_INTERNAL_TARIFF_DATA";

//TARIFF CODES
export const SAVE_INTERNAL_TARIFF_CODES = "SAVE_INTERNAL_TARIFF_CODES";

//Pay Report
export const SAVE_PAY_REPORTS = "SAVE_PAY_REPORTS";
export const SAVE_PAY_REPORT = "SAVE_PAY_REPORT";
export const CLEAR_ALL_PAY_REPORT_DATA = "CLEAR_ALL_PAY_REPORT_DATA";

//QA Report
export const SAVE_QA_REPORT = "SAVE_QA_REPORT";

//Vendor Pay Report Transaction
export const SAVE_VENDOR_PAY_REPORTS_TRANSACTION = "SAVE_VENDOR_PAY_REPORTS_TRANSACTION";
export const SAVE_PAY_REPORT_TRANSACTION_WIDGET = "SAVE_PAY_REPORT_TRANSACTION_WIDGET";
export const SAVE_PAY_REPORT_TRANSACTION_GENERAL_DATA = "SAVE_PAY_REPORT_TRANSACTION_GENERAL_DATA";

//Users 
export const SAVE_USERS = "SAVE_USERS";
export const SAVE_USER_PROFILE_DETAILS = "SAVE_USER_PROFILE_DETAILS";
export const CLEAR_ALL_USER_DATA = "CLEAR_ALL_USER_DATA";

//Users 
export const SAVE_ALL_RETAILERS = "SAVE_ALL_RETAILERS";
export const SAVE_RETAILER = "SAVE_RETAILER";
export const CLEAR_ALL_RETAILERS = "CLEAR_ALL_RETAILERS";

// Quality Assurance 
export const SAVE_ALL_QualityAssurance_List = "SAVE_ALL_QualityAssurance_List";
export const SAVE_SINGLE_QA = "SAVE_SINGLE_QA"

// DMO VDO
export const SAVE_ALL_DMO_VDO = "SAVE_ALL_DMO_VDO";
export const SAVE_SINGLE_DMO_VDO = "SAVE_SINGLE_DMO_VDO"
export const CLEAR_ALL_DMO_VDO = " CLEAR_ALL_DMO_VDO";

// GAS DISTRIBUTORS FEES
export const SAVE_GAS_DISTRIBUTORS_FEES_SAVINGS_MAPPINGS = "SAVE_GAS_DISTRIBUTORS_FEES_SAVINGS_MAPPINGS";
export const SAVE_GAS_DISTRIBUTORS_FEES_DETAILS = "SAVE_GAS_DISTRIBUTORS_FEES_DETAILS"
export const SAVE_GAS_DISTRIBUTORS_FEES_RECORD_SAVE_STATUS = " SAVE_GAS_DISTRIBUTORS_FEES_RECORD_SAVE_STATUS";
export const SAVE_GAS_DISTRIBUTORS_FEES_RECORD_UPDATE_STATUS = "SAVE_GAS_DISTRIBUTORS_FEES_RECORD_UPDATE_STATUS"
export const CLEAR_ALL_GAS_DISTRIBUTORS_FEES_DATA = " CLEAR_ALL_GAS_DISTRIBUTORS_FEES_DATA";

//PRODUCT
export const SAVE_ALL_PRODUCTS = 'SAVE_ALL_PRODUCTS';
export const SAVE_PRODUCT_LIST = 'SAVE_PRODUCT_LIST';
export const SAVE_PRODUCT_DETAILS = 'SAVE_PRODUCT_DETAILS';
export const CLEAR_ALL_PRODUCT_DATA = 'CLEAR_ALL_PRODUCT_DATA';

//REPORTS
export const UPDATE_RETAILER_SALES_SUBMISSION_STATUS = 'UPDATE_RETAILER_SALES_SUBMISSION_STATUS';
export const UPDATE_RETAILER_SALES_STATUS = 'UPDATE_RETAILER_SALES_STATUS';
export const UPDATE_RETAILER_SALES_PAYMENT_STATUS = 'UPDATE_RETAILER_SALES_PAYMENT_STATUS';
export const CLEAR_ALL_SALES_SUBMISSION_STATUS_MAPPING_DATA = 'CLEAR_ALL_SALES_SUBMISSION_STATUS_MAPPING_DATA';

//MSATS
export const SAVE_ADDRESS_LIST = "SAVE_ADDRESS_LIST";
export const SAVE_NMI_DETAILS = "SAVE_NMI_DETAILS";
export const SAVE_SELECTED_ADDRESS = "SAVE_SELECTED_ADDRESS";

//VENDORS
export const SAVE_SAVINGS_MAPPINGS = 'SAVE_SAVINGS_MAPPINGS';
export const SAVE_VENDOR_DETAILS = 'SAVE_VENDOR_DETAILS';
export const SAVE_VENDOR_RECORD_SAVE_STATUS = 'SAVE_VENDOR_RECORD_SAVE_STATUS';
export const SAVE_VENDOR_RECORD_UPDATE_STATUS = 'SAVE_VENDOR_RECORD_UPDATE_STATUS';
export const CLEAR_ALL_VENDOR_DATA = 'CLEAR_ALL_VENDOR_DATA';

//GAS ADDRESS LIST
export const SAVE_ADDRESS_SAVINGS_MAPPINGS = 'SAVE_ADDRESS_SAVINGS_MAPPINGS';
export const SAVE_ADDRESS_DETAILS = 'SAVE_ADDRESS_DETAILS';
export const SAVE_ADDRESS_RECORD_SAVE_STATUS = 'SAVE_ADDRESS_RECORD_SAVE_STATUS';
export const SAVE_ADDRESS_RECORD_UPDATE_STATUS = 'SAVE_ADDRESS_RECORD_UPDATE_STATUS';
export const CLEAR_ALL_ADDRESS_DATA = 'CLEAR_ALL_ADDRESS_DATA';

//ELEC RATES LIST
export const SAVE_ELEC_RATES_SAVINGS_MAPPINGS = 'SAVE_ELEC_RATES_SAVINGS_MAPPINGS';
export const SAVE_ELEC_RATES_DETAILS = 'SAVE_ELEC_RATES_DETAILS';
export const SAVE_ELEC_RATES_RECORD_SAVE_STATUS = 'SAVE_ELEC_RATES_RECORD_SAVE_STATUS';
export const SAVE_ELEC_RATES_RECORD_UPDATE_STATUS = 'SAVE_ELEC_RATES_RECORD_UPDATE_STATUS';
export const CLEAR_ALL_ELEC_RATES_DATA = 'CLEAR_ALL_ELEC_RATES_DATA';

//GAS RATES LIST
export const SAVE_GAS_RATES_SAVINGS_MAPPINGS = 'SAVE_GAS_RATES_SAVINGS_MAPPINGS';
export const SAVE_GAS_RATES_DETAILS = 'SAVE_GAS_RATES_DETAILS';
export const SAVE_GAS_RATES_RECORD_SAVE_STATUS = 'SAVE_GAS_RATES_RECORD_SAVE_STATUS';
export const SAVE_GAS_RATES_RECORD_UPDATE_STATUS = 'SAVE_GAS_RATES_RECORD_UPDATE_STATUS';
export const CLEAR_ALL_GAS_RATES_DATA = 'CLEAR_ALL_GAS_RATES_DATA';

//ELEC DIS FEES LIST
export const SAVE_ELEC_DIS_FEE_SAVINGS_MAPPINGS = 'SAVE_ELEC_DIS_FEE_SAVINGS_MAPPINGS';
export const SAVE_ELEC_DIS_FEE_DETAILS = 'SAVE_ELEC_DIS_FEE_DETAILS';
export const SAVE_ELEC_DIS_FEE_RECORD_SAVE_STATUS = 'SAVE_ELEC_DIS_FEE_RECORD_SAVE_STATUS';
export const SAVE_ELEC_DIS_FEE_RECORD_UPDATE_STATUS = 'SAVE_ELEC_DIS_FEE_RECORD_UPDATE_STATUS';
export const CLEAR_ALL_ELEC_DIS_FEE_DATA = 'CLEAR_ALL_ELEC_DIS_FEE_DATA';

//ELEC BPIDs LIST
export const SAVE_ELEC_BPID_SAVINGS_MAPPINGS = 'SAVE_ELEC_BPID_SAVINGS_MAPPINGS';
export const SAVE_ELEC_BPID_DETAILS = 'SAVE_ELEC_BPID_DETAILS';
export const SAVE_ELEC_BPID_RECORD_SAVE_STATUS = 'SAVE_ELEC_BPID_RECORD_SAVE_STATUS';
export const SAVE_ELEC_BPID_RECORD_UPDATE_STATUS = 'SAVE_ELEC_BPID_RECORD_UPDATE_STATUS';
export const CLEAR_ALL_ELEC_BPID_DATA = 'CLEAR_ALL_ELEC_BPID_DATA';

//GAS BPIDs LIST
export const SAVE_GAS_BPID_SAVINGS_MAPPINGS = 'SAVE_GAS_BPID_SAVINGS_MAPPINGS';
export const SAVE_GAS_BPID_DETAILS = 'SAVE_GAS_BPID_DETAILS';
export const SAVE_GAS_BPID_RECORD_SAVE_STATUS = 'SAVE_GAS_BPID_RECORD_SAVE_STATUS';
export const SAVE_GAS_BPID_RECORD_UPDATE_STATUS = 'SAVE_GAS_BPID_RECORD_UPDATE_STATUS';
export const CLEAR_ALL_GAS_BPID_DATA = 'CLEAR_ALL_GAS_BPID_DATA';

//RETAILER REWARDS LIST
export const SAVE_RETAILER_REWARDS_SAVINGS_MAPPINGS = 'SAVE_RETAILER_REWARDS_SAVINGS_MAPPINGS';
export const SAVE_RETAILER_REWARDS_DETAILS = 'SAVE_RETAILER_REWARDS_DETAILS';
export const SAVE_RETAILER_REWARDS_RECORD_SAVE_STATUS = 'SAVE_RETAILER_REWARDS_RECORD_SAVE_STATUS';
export const SAVE_RETAILER_REWARDS_RECORD_UPDATE_STATUS = 'SAVE_RETAILER_REWARDS_RECORD_UPDATE_STATUS';
export const CLEAR_ALL_RETAILER_REWARDS_DATA = 'CLEAR_ALL_RETAILER_REWARDS_DATA';

//DMOVDO SCRIPT LIST
export const SAVE_DMOVDO_SCRIPT_SAVINGS_MAPPINGS = 'SAVE_DMOVDO_SCRIPT_SAVINGS_MAPPINGS';
export const SAVE_DMOVDO_SCRIPT_DETAILS = 'SAVE_DMOVDO_SCRIPT_DETAILS';
export const SAVE_DMOVDO_SCRIPT_RECORD_SAVE_STATUS = 'SAVE_DMOVDO_SCRIPT_RECORD_SAVE_STATUS';
export const SAVE_DMOVDO_SCRIPT_RECORD_UPDATE_STATUS = 'SAVE_DMOVDO_SCRIPT_RECORD_UPDATE_STATUS';
export const CLEAR_ALL_DMOVDO_SCRIPT_DATA = 'CLEAR_ALL_DMOVDO_SCRIPT_DATA';

//GAS DISTRIBUTOR
export const SAVE_GAS_DISTRIBUTORS_SAVINGS_MAPPINGS = 'SAVE_GAS_DISTRIBUTORS_SAVINGS_MAPPINGS';
export const SAVE_GAS_DISTRIBUTORS_DETAILS = 'SAVE_GAS_DISTRIBUTORS_DETAILS';
export const SAVE_GAS_DISTRIBUTORS_RECORD_SAVE_STATUS = 'SAVE_GAS_DISTRIBUTORS_RECORD_SAVE_STATUS';
export const SAVE_GAS_DISTRIBUTORS_RECORD_UPDATE_STATUS = 'SAVE_GAS_DISTRIBUTORS_RECORD_UPDATE_STATUS';
export const CLEAR_ALL_GAS_DISTRIBUTORS_DATA = 'CLEAR_ALL_GAS_DISTRIBUTORS_DATA';

//ELEC DISTRIBUTOR
export const SAVE_ELEC_DISTRIBUTORS_SAVINGS_MAPPINGS = 'SAVE_ELEC_DISTRIBUTORS_SAVINGS_MAPPINGS';
export const SAVE_ELEC_DISTRIBUTORS_DETAILS = 'SAVE_ELEC_DISTRIBUTORS_DETAILS';
export const SAVE_ELEC_DISTRIBUTORS_RECORD_SAVE_STATUS = 'SAVE_ELEC_DISTRIBUTORS_RECORD_SAVE_STATUS';
export const SAVE_ELEC_DISTRIBUTORS_RECORD_UPDATE_STATUS = 'SAVE_ELEC_DISTRIBUTORS_RECORD_UPDATE_STATUS';
export const CLEAR_ALL_ELEC_DISTRIBUTORS_DATA = 'CLEAR_ALL_ELEC_DISTRIBUTORS_DATA';

//ONLINE SIGNUP SCRIPTS
export const SAVE_ONLINE_SIGNUP_SCRIPTS_SAVINGS_MAPPINGS = 'SAVE_ONLINE_SIGNUP_SCRIPTS_SAVINGS_MAPPINGS';
export const SAVE_ONLINE_SIGNUP_SCRIPTS_DETAILS = 'SAVE_ONLINE_SIGNUP_SCRIPTS_DETAILS';
export const SAVE_ONLINE_SIGNUP_SCRIPTS_RECORD_SAVE_STATUS = 'SAVE_ONLINE_SIGNUP_SCRIPTS_RECORD_SAVE_STATUS';
export const SAVE_ONLINE_SIGNUP_SCRIPTS_RECORD_UPDATE_STATUS = 'SAVE_ONLINE_SIGNUP_SCRIPTS_RECORD_UPDATE_STATUS';
export const CLEAR_ALL_ONLINE_SIGNUP_SCRIPTS_DATA = 'CLEAR_ALL_ONLINE_SIGNUP_SCRIPTS_DATA';

//ALL COMPARISONS
export const SAVE_ALL_COMPARISONS_SAVINGS_MAPPINGS = 'SAVE_ALL_COMPARISONS_SAVINGS_MAPPINGS';
export const CLEAR_ALL_ALL_COMPARISONS_DATA = 'CLEAR_ALL_ALL_COMPARISONS_DATA';

//RETAILER SCRIPTS
export const SAVE_RETAILER_SCRIPTS_SAVINGS_MAPPINGS = 'SAVE_RETAILER_SCRIPTS_SAVINGS_MAPPINGS';
export const SAVE_RETAILER_SCRIPTS_DETAILS = 'SAVE_RETAILER_SCRIPTS_DETAILS';
export const SAVE_RETAILER_SCRIPTS_RECORD_SAVE_STATUS = 'SAVE_RETAILER_SCRIPTS_RECORD_SAVE_STATUS';
export const SAVE_RETAILER_SCRIPTS_RECORD_UPDATE_STATUS = 'SAVE_RETAILER_SCRIPTS_RECORD_UPDATE_STATUS';
export const CLEAR_ALL_RETAILER_SCRIPTS_DATA = 'CLEAR_ALL_RETAILER_SCRIPTS_DATA';
